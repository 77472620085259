import React from 'react';

const Content = () => (
  <section className="py-12 bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-gray-700 sm:py-16 lg:py-20">
    <div className="px-6 mx-auto max-w-7xl sm:px-12 lg:px-16 xl:px-20">
      <article
        className="max-w-3xl mx-auto prose lg:prose-lg prose-img:rounded-xl prose-a:text-secondary-500 prose-p:font-medium prose-a:font-medium dark:prose-invert prose-ol:font-medium prose-ul:font-medium">
        <p>
          Star Beat Tech Private Ltd. (“we” , “StarBeat” or ”us” or “company”) is the owner of the
          website/application namely
          StarBeat (“Website/ Application”) respects the privacy of its Users (“you”) and informs you of the
          policies regarding
          the collection, use and disclosure of personal information that we receive from the users using the
          Website/Application.
          We know that you care about how your personal information is used, stored and shared, and we take your
          privacy
          seriously. Please read the following to learn more about our privacy policy.
        </p>
        <p>
          The Privacy Policy describes the policies and procedures followed by the Company with respect to
          collection, transfer,
          storage, use and disclosure of your Information and other data that we gather when you are accessing the
          Website,
          Application and the Service being offered. We receive information about you from various sources,
          including: (i) if you
          register using our Website/Application by creating a membership account for accessing the Service
          ("Account") and (ii)
          when you download and use the Application and (iii) when you sign in through our Website.
        </p>
        <p>
          BY DOWNLOADING, INSTALLING, USING OR OTHERWISE ACCESSING THE SERVICE, YOU AGREE TO THIS PRIVACY POLICY AND
          CONSENT TO
          THE COLLECTION, TRANSFER, STORAGE, DISCLOSURE AND OTHER USES OF YOUR INFORMATION AS DESCRIBED IN THIS
          PRIVACY POLICY. IF
          YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT USE THE SERVICES. By using the website/application,
          you agree to
          the following:
        </p>

        <hr />

        <h2>
          1. Personal Information
        </h2>
        <p>
          The Company may receive and store personal information which you provide during your use of the website.
          <span className="font-bold">“Personal
            information”</span> includes specific identifiable information about an individual or entity which may
          be used to
          identify,
          contact or locate that person or entity (eg., name, address, e-mail address or phone number). Sometimes,
          you will have a
          choice whether or not to provide such information. Your failure to provide certain information may make
          some features
          and/or functionality of the Website/Application unavailable to you.
        </p>

        <h3>
          Collection of Information from you:
        </h3>
        <p>
          We may collect your Personal information such as (i) first and last name; (ii) a home or other physical
          address; (iii)
          an email address or other contact information (including mobile / landline numbers); (iv) birthday; (v)
          information
          regarding your use of our Services through the Website/Application including your location details. To
          fully use our
          Website/Application, you will need to register through our Website/ Application by creating a membership
          account by
          providing your e-mail address/phone number. When you provide Personal Information through our Service, the
          information
          may be sent to servers located in India.
        </p>
        <p>
          We may use your Personal Information to send emails periodically describing promotions, events or changes
          relating to
          the Website/ Application and/ or Services. You may opt-out of receiving promotional emails by following
          the instructions
          in the relevant correspondence. Once we have processed your opt-out request, We will not send you further
          promotional
          emails unless you opt back in to receiving such communications. Whether you opt out of receiving
          promotional emails or
          not, We reserve the right to communicate with you via email with respect to important information, notices
          or changes
          arising out of or relating to the Site.
        </p>

        <hr />

        <h2>
          2. Non-Personal Information
        </h2>
        <p>
          We may receive and store certain Non-Personal Information whenever you interact with the
          Website/Application. <span className="font-bold">“Non-
            Personal Information”</span> includes information that, by itself, does not identify a specific
          individual or
          entity (e.g.,
          demographic information, usage data or downloads). Non-Personal Information may be gathered by the
          following methods:
        </p>
        <ol>
          <li>
            <span className="font-bold">(a) Cookies:</span> Cookies are small pieces of information that are stored by
            your browser on your computer’s
            hard drive which
            work by assigning to your computer a unique number that has no meaning outside of the Website/
            Application. Most web
            browsers automatically accept cookies, but you can usually configure your browser to prevent this.
            However, because
            cookies enable you to take advantage of some of the Website/ Application’s features, StarBeat recommends
            that you leave
            them turned on or you may not have access or use of such features.
          </li>
          <li>
            <span className="font-bold">(b) Metadata:</span> Metadata is data collected and used by us to facilitate the
            understanding,
            characteristics, and management
            of data arising out of or relating to the Website/ Application, to facilitate and monitor system usage
            and, in certain
            instances, for fraud prevention and other services. Some examples of information and metadata that we
            may collect and
            analyse include, without limitation, the Internet protocol (“IP”) address used to connect your computer
            to the Internet,
            computer and connection information (such as browser type, version, and time zone setting, browser
            plug-in types and
            versions, operating system, and platform), the full uniform Resource Locator (“URL”) click sequence to,
            through, and
            from the Website, including date and time; cookie number; and any products, services or features you use
            or interact
            with arising out of or in connection with your use of the Website/ Application.
          </li>
          <li>
            <span className="font-bold">(c) Tools:</span> We may use software tools to measure and collect information
            about your use of the Website/
            Application,
            including page response time, upload and file transfer errors, interaction information (such as
            scrolling, clicks, and
            mouse-overs), features use, and methods. Sometimes, you will have a choice whether or not to provide
            information, but if
            you fail to provide certain information it could make some features and/or functionality of the Website/
            Application
            unavailable to you. We will use information you provide to respond to your request, communicate with
            you, and to improve
            the Website/ Application.
          </li>
          <li>
            <span className="font-bold">(d) Information from Other Sources:</span> We may receive information about you
            from our third-party service
            providers, or
            other sources such as purchase or redemption information, page-view information, technical, fulfilment,
            advertising, and
            search information.
          </li>
        </ol>

        <hr />

        <h2>
          3. Social Networks
        </h2>
        <p>
          Certain features of the Service may enable you to connect to social networking sites and for us to obtain
          additional
          information about you and your network. In such instances, we may be able to collect certain Personal and
          Non-Personal
          Information from your social networking site to make such information available to us. This information
          may include, but
          is not limited to, your name, profile picture, gender, social network user ID, e-mail address, your
          country,
          organisation and links on your profile page, your language, names and profile pictures of your social
          networking site,
          “friends”, and other information you have included in your social networking site profile. We may
          associate, combined
          and/or use information collection through social networking sites in accordance with this Privacy Policy.
        </p>

        <hr />

        <h2>
          4. Other Information
        </h2>
        <p>
          If you use our website/application, we may collect information about your IP address, browser type, domain
          names, other
          website addresses referred, access times etc. We might look at what site you came from, duration of time
          spent on our
          website, pages accessed or what site you visit when you leave us. Your use will be automatically collected
          by us and
          this information shall be used by us to operate and maintain the quality of service.
        </p>

        <hr />

        <h2>
          5. Financial Information
        </h2>
        <p>
          <span className="font-bold">“Financial Information”</span> means information relating to payments and billing
          (e.g. accounts information,
          billing address,
          credit/debit card number, and credit/debit card expiry date)
        </p>

        <h3>
          Third-party Services:
        </h3>
        <p>
          We do not store your financial information on our servers. We use a third-party payment gateway for all
          payment
          transactions. We might collect your billing name, billing address and payment method when you use/access
          our Service. We
          never collect your credit card number or credit card expiry date or other details pertaining to your
          credit card on our
          Website/ Application. Credit/debit card information will be obtained and processed by our online payment
          partner who is
          protected by encryption, like SSL.
        </p>
        <p>
          The Information is collected for billing and payment purposes only and will be protected and administered
          by us and/our
          authorised third-party partners. However, we recommend that you consult the privacy statements of our
          third-party online
          payment partners by clicking on the “privacy” link typically located at the bottom of their webpage. You
          agree and
          acknowledge that we shall not be liable for any breach of your privacy of Personal Information/Financial
          Information or
          loss incurred by these third-party payment partners.
        </p>

        <hr />

        <h2>
          6. How Do We Use The Information Collected From You
        </h2>
        <p>
          We may use information that we collect about you to:
        </p>
        <ul>
          <li>
            administer and personalize the Service for you;
          </li>
          <li>
            enable your access to and use of our Service;
          </li>
          <li>
            publish information about you on our Website/ Application;
          </li>
          <li>
            send you marketing/promotional communication;
          </li>
          <li>
            deliver and improve our products and Service, and manage our business;
          </li>
          <li>
            provide you with an enhanced experience when using the Service while suggesting and recommending content
            posted by
            Influencers/uploaders best suited for the User;
          </li>
          <li>
            send to you statements and invoices and, collect payments from you;
          </li>
          <li>
            manage your account and provide you with customer support;
          </li>
          <li>
            communicate with you by email, postal mail, telephone and/or mobile devices about products or services
            that may be of
            interest to you either from us or other third-parties;
          </li>
          <li>
            provide your information to our third-party partners for marketing or promotional purposes;
          </li>
          <li>
            use Personal Information and Non-Personally Information and other information (including information
            from online and
            offline third-party sources) to create aggregated data for analytical and other purposes. Use this
            information to do
            internal research on Users' demographics, interests, and behavior to better understand, protect and
            serve Users. This
            information is compiled and analyzed on an aggregated basis; and
          </li>
          <li>
            We may, with your consent, send push notifications in our applications. You may disable these by
            declining them or
            changing the app's settings. To the extent provided, we may, with your consent, send SMS messages to
            your mobile
            telephone for authentication and security purposes. You may opt out of receiving such messages at any
            time.
          </li>
        </ul>

        <hr />

        <h2>
          7. Account and Audio/Video Settings
        </h2>
        <p>
          As part of our Service, we offer you the ability to limit the distribution of your content, including your
          videos. This
          section explains some of your choices.
        </p>
        <p>
          <span className="font-bold">Profile:</span> Some of our online subscription plans enable you to "hide" your
          profile or otherwise make it
          inaccessible on
          our website. Your profile will, however, remain accessible to persons who have access to your account or
          content.
        </p>
        <p>
          <span className="font-bold">Team Members:</span> Some of our subscription plans enable you to grant
          account-level
          access rights to others.
          Persons with
          such access ("Team Members") may be able to see and potentially alter your data. You may revoke or
          downgrade Team Member
          access at any time.
        </p>
        <p>
          <span className="font-bold">Videos/Audio:</span> When you are interacting live with the Influencers, those
          videos/audios are recorded which
          are visible to
          other followers/subscribers of that particular Influencer/s. However, we enable you to manage the privacy
          settings of
          your audios/videos. On StarBeat platform, for example, your options, which depend on your subscription
          plan, may include
        </p>
        <ul>
          <li>
            <span className="font-bold">Public (or "anyone"):</span> Your video will be publicly-available.
          </li>
          <li>
            <span className="font-bold">Just you (i.e., "only me"):</span> Your video will be available to you,
            your influencer and authorized Team
            Members.
          </li>
          <li>
            <span className="font-bold">Selected people:</span> Your video will be available to users you select (such
            as users you follow or
            specifically designated
            users).
          </li>
          <li>
            <span className="font-bold">Password-protected:</span> Your video may be accessed by anyone who enters the
            password you have set.
          </li>
          <li>
            <span className="font-bold">Private link:</span> Your video may be accessed by anyone who enters the link
            assigned to it.
          </li>
        </ul>

        <hr />

        <h2>
          8 . With Whom We Share Your Information
        </h2>
        <ol>
          <li>
            We do not share your Personal Information with others except as indicated in this Privacy Policy. In the
            event, we
            propose to share your Personal Information, except as indicated in this Privacy Policy, we will inform
            you and give you
            an opportunity to opt out of having your Personal Information shared in the proposed manner.
          </li>
          <li>
            <span className="font-bold">Third Party Service Providers:</span> We may disclose your Personal Information
            to third parties such as
            trusted partners who
            work on behalf of or with us under confidentiality agreements or such similar arrangements. These
            entities may use your
            Personal Information for performing marketing/ advertising/ promotional services, delivering goods or
            services,
            administering promotions, analyzing data and usage of the Service through the Website and Application,
            processing
            credit/debit card payments, operating the Service or providing support and maintenance services for the
            same, or
            providing customer service. Our partners provide assurance that they take reasonable steps to safeguard
            the data they
            hold on our behalf, although data security cannot be guaranteed. Analytics companies may access
            anonymous data (such as
            your IP address or device ID) to help us understand how our services are used.
          </li>
          <li>
            <span className="font-bold">Ad companies:</span> We may disclose other Non-Personal Information to
            third-party advertisers and advertising
            agencies,
            partners, and other parties in order for the Company and such third parties to analyse (a) the
            performance of, to
            operate and improve the Service offered through the Website and Application, and (b) the behaviour of
            Users and to
            target offers to Users depending upon their requirements. We may also disclose, use or publish this
            information for
            promoting the Services offered under the Website(s) and/or Application(s). These third parties are
            subject to
            confidentiality agreements or such similar arrangements with us and other legal restrictions that
            prohibit their use of
            the information we provide them for any other purpose except to facilitate the specific outsourced
            operation, unless you
            have explicitly agreed or given your prior permission to them for additional uses.
          </li>
          <li>
            <span className="font-bold">Third Party Websites:</span> There may be a number of places on our Service
            where you may click on a link to
            access other
            websites that do not operate under this Privacy Policy. For example, if you click on an advertisement on
            our Service,
            you may be taken/ directed to a website(s) that we do not own, control or operate and we do not bear any
            responsibility
            towards your access to such website(s). These third- party website(s) may independently solicit and
            collect information,
            including personal information, from you and, in some instances, provide us with information about your
            activities on
            those website(s). We recommend that you consult the privacy statements of all third-party websites you
            visit by clicking
            on the “privacy” link typically located at the bottom of the webpage you are visiting. You agree and
            acknowledge that we
            shall not be liable for any breach of your privacy of Personal Information or loss incurred by your use
            of these
            website(s). We are not responsible for the privacy policies and/or practices on other website(s). This
            Privacy Policy
            only governs information collected by the Company.
          </li>
          <li>
            <span className="font-bold">Other Situations:</span> To operate the Service, we also may make identifiable
            and anonymous information
            available to third
            parties in circumstances: (1) with your express consent, (2) where Your consent will not be required for
            disclosure i.e.
            when we have a good faith belief it is required by law, it is necessary to protect our rights or
            property, or to any
            successor or purchaser in a merger, acquisition, liquidation, dissolution or sale of assets or any such
            arrangement of
            like nature, but we will attempt to notify you, to the extent permitted by law to do so.
          </li>
        </ol>

        <p>
          Please remember that if you choose to provide Personal Information using certain features of the Service
          that
          information is governed by the privacy settings of those particular features and, unless selected
          otherwise, may be
          available to the Users of the Application or may be published on the Website, which is subject to indexing
          by third
          party search engines. Individuals reading this information may use or disclose it to other individuals or
          entities
          without our control and without your knowledge. Therefore, we urge you to think carefully about including
          any specific
          information.
        </p>

        <hr />

        <h2>
          9. Security
        </h2>
        <p>
          We take security measures to help safeguard your Personal Information from unauthorized access and
          disclosure, and as
          specified by applicable law. While we take these reasonable efforts to safeguard your Personal
          Information, you
          acknowledge and agree that no system or transmission of data over the Internet or any other public network
          can be
          guaranteed to be 100% secure. Users should also take care with how they handle and disclose their personal
          information
          and should avoid sending Personal Information through insecure email. Despite our efforts, you also play
          an important
          role in protecting against unauthorised access to your User Account information, password and computer. We
          recommend
          that you always log out of your User Account if you leave your computer and/or are using a shared
          computer.
        </p>

        <hr />

        <h2>
          10. Children's Privacy
        </h2>
        <p>
          We do not knowingly collect Personal Information from persons under the age of 18 or allow them to
          register a user
          Account, and if you are under the age of 18, you must not attempt to register with us and/or submit any
          Personal
          Information to us. If it comes to Our attention that any Personal Information has been collected from a
          person under the
          age of 18, we will delete this information as quickly as possible including the membership account.
        </p>

        <hr />

        <h2>
          11. Amendments to the Privacy Policy
        </h2>
        <p>
          We may amend this Privacy Policy from time to time. Use of information we collect now is subject to the
          Privacy Policy
          in effect at the time such information is used. If we decide to change our Privacy Policy, we will post
          those changes on
          this page so that you are always aware of what information we collect, how we use it, and under what
          circumstances we
          disclose it. We recommend that you check our Service from time to time to inform yourself of any changes
          in this Privacy
          Policy or any of our other policies. Users are bound by any changes to the Privacy Policy when they
          continue to use the
          Website/Application after such changes have been first posted.
        </p>

        <hr />

        <h2>
          12. Data Retention
        </h2>
        <p>
          We retain your data for as long as you have a membership account with us. When you close an account on the
          Website/
          Application, we will delete its content. We may retain logs of automatically collected information (for
          internal
          analytics and security purposes); your email address; your tax information; communications with you; and
          your
          transactional information (for auditing, tax, and financial purposes). Also, your history/interactions
          with
          uploaders/influencers that is available on the servers will be deleted only when they delete their
          account. User at all
          times has the right to withdraw consent by communicating it to Company or discontinuing use by deleting
          the Website/
          Application. When we no longer have a business reason for retaining data/ information, we will delete or
          anonymise it.
          If we receive legal process pertaining to your membership account, we will retain your data/ information
          for as long as
          we in good faith believe is necessary to comply with the legal process. Similarly, if we believe that your
          account has
          been involved in wrongdoing, we may preserve your data to defend or assert our rights.
        </p>

        <hr />

        <h2>
          13. How to Contact Us
        </h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us by email as follows:<br /><a href="#"
            title="">
            hello@starbeat.com
          </a>
        </p>

        <hr />

        <h2>
          14. Grievance Officer
        </h2>
        <p>
          In accordance with Information Technology Act, 2000 and rules made there under, in case of grievances you
          can contact:
        </p>

        <p>
          <span className="font-bold">Star Beat Tech Pvt Ltd</span><br />
          First Floor, No-1216 and 1217, North Block,<br />
          24th Main Sector1, Hosur Sarjapur Road HSR Layout,<br />
          Bengaluru (Bangalore) Urban, Karnataka - 560102<br />
          <a href="#" title="">
            hello@starbeat.com</a>
        </p>

        <hr />

        <p>
          In the event you wish to make a complaint regarding any violation of the provisions of the Privacy Policy,
          you may send
          a written complaint to the Grievance Officer, who shall redress the complaint within 30 days time.
        </p>
      </article>
    </div>
  </section>
);

export default Content;
